@font-face {
  font-family: "Poppins";
  src        : url("Poppins-Light.ttf");
}

body {
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 1px;
  background: #15162c;
  color: #fff;
}

.navbar-light {
  background: #ffffff;
}

.btn {
  color: #fff;
}

.btn:hover {
  color: #a71db6;
  text-decoration: none;
}

.fit-content {
  width: fit-content;
}

html {
  overflow-x: hidden;  
}

.abs-center {
  display        : flex;
  align-items    : center;
  justify-content: center;
  min-height     : 80vh;
  text-align     : center;
}

p {
  letter-spacing: 1px;
}

/* SHADOW*/

.light-b-shadow {
  box-shadow: 0px 5px 7px 0px #444444;
}
